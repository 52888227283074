import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Text } from 'theme-ui'

const PageAbout = props => {
  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='About us'
          subheader=''
        />
      </Stack>
      <Stack>
        <Main>
        <Text variant='p'>Welcome to Pinout.tech, a squad of young and dynamic tech enthusiasts dedicated to delivering you the freshest, most immersive content from the digital realm. We understand the information overload in the tech universe, and sorting through it all can be a real challenge. That's where we come in - to curate the absolute cream of the crop and serve it right to you.</Text>
        <Text variant='p'>Our focal point revolves around crafting comprehensive compilations and tailored selections that cater to your tech and gaming cravings. Our range covers everything from the latest must-have gadgets to obscure computer marvels you might have overlooked. Our constant pursuit of new and exhilarating content ensures that our offerings remain perpetually dynamic and pertinent.</Text>
        <Text variant='p'>We're a compact crew of fervent tech bloggers, driven by the goal of providing content that's a cut above the rest. We firmly believe that everyone deserves access to groundbreaking insights and innovative concepts, and we're here to bring that vision to life. Whether you seek entertainment, knowledge, or inspiration, consider us your digital companions.</Text>
        <Text variant='p'>So, embark on this odyssey with us to elevate your tech and gaming experience. We pledge that you won't be let down. With Pinout.tech, the pinnacle of technology, gaming, and computer marvels will forever be at your fingertips.</Text>
      </Main>
      </Stack>
    </Layout>
  )
}

export default PageAbout
